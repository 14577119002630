import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import _process from "process";
var exports = {};
var process = _process;

/**
 * Modules
 */
var path = _path;
/**
 * Vars
 */

var relative = path.relative;
var lastCwd = process.cwd();
var cache = Object.create(null);
/**
 * Expose cachedPathRelative
 */

exports = cachedPathRelative;
/**
 * cachedPathRelative
 */

function cachedPathRelative(from, to) {
  // If the current working directory changes, we need
  // to invalidate the cache
  var cwd = process.cwd();

  if (cwd !== lastCwd) {
    cache = Object.create(null);
    lastCwd = cwd;
  }

  if (cache[from] && cache[from][to]) return cache[from][to];
  var result = relative.call(path, from, to);
  cache[from] = cache[from] || Object.create(null);
  cache[from][to] = result;
  return result;
}

export default exports;